import { SessionManager } from "@toruslabs/session-manager";
import { JRPC_METHODS, safebtoa, SDK_MODE, SDK_MODE_TYPE, storageAvailable } from "@web3auth/auth";
import URI from "urijs";

import loginPerfModule from "@/store/modules/loginPerf";

import { clearLoginDetailsStorage } from "./customauth";
import { PREFERENCES_OP } from "./enums";
import { AuthSession } from "./interfaces";
import { validateInternalHost } from "./utils";

export async function redirectToDapp(
  {
    redirectUrl,
    popupWindow,
    sessionTime,
    sessionId,
    sessionNamespace = "",
    loginId,
    error,
    appState,
    storageServerUrl,
    instanceId,
    syncSession = true,
    sdkMode,
  }: {
    redirectUrl: string;
    popupWindow: boolean;
    sessionTime: number;
    sessionNamespace: string;
    loginId: string;
    storageServerUrl: string;
    syncSession?: boolean;
    sessionId?: string;
    error?: string;
    appState?: string;
    instanceId?: string;
    sdkMode?: SDK_MODE_TYPE;
  },
  { result }: { result: AuthSession }
): Promise<void> {
  if (!error && !sessionId) {
    throw new Error("Session ID is required.");
  }
  const isInternalHost = validateInternalHost(redirectUrl);
  const finalResult = { ...result };
  if (!isInternalHost) {
    delete finalResult.oAuthPrivateKey;
    delete finalResult.tKey;
    delete finalResult.walletKey;
    delete finalResult.authToken;
    delete finalResult.metadataNonce;
    delete finalResult.keyMode;
    delete finalResult.shareDetails;
  }

  let finalUrl: URI;
  if (!error && syncSession) {
    const sessionManager = new SessionManager<AuthSession>({
      sessionServerBaseUrl: storageServerUrl ?? "",
      sessionNamespace,
      sessionId,
      sessionTime,
      allowedOrigin: new URI(redirectUrl).origin(),
    });
    await sessionManager.createSession(finalResult);
  }

  if (instanceId && storageAvailable("localStorage")) {
    clearLoginDetailsStorage(instanceId);
  }

  await loginPerfModule.markRouteAndTime({
    route: "end",
    isEnd: true,
    operation: PREFERENCES_OP,
  });

  const dappOrigin = new URI(redirectUrl).origin();
  if (sdkMode === SDK_MODE.IFRAME) {
    window.parent.postMessage({ type: JRPC_METHODS.LOGIN_SUCCESS, data: { sessionId, sessionNamespace, state: appState, error } }, dappOrigin);
    return;
  }

  if (popupWindow) {
    if (!loginId) {
      throw new Error("LoginId not specified");
    }
    const { SecurePubSub } = await import("@toruslabs/secure-pub-sub");
    // Publish a message on the Broadcast channel.
    const securePubSub = new SecurePubSub({ sameIpCheck: true, allowedOrigin: dappOrigin });
    const dataToSend = { error, data: { sessionId, sessionNamespace }, ...(error ? { state: appState } : {}) };
    await securePubSub.publish(loginId, JSON.stringify(dataToSend));

    // shouldn't be necessary as the listener will close this popup
    // automatically on receiving the message.
    setTimeout(() => {
      window.close();
    }, 5000);
  } else {
    finalUrl = new URI(redirectUrl);
    const hashParams = new URLSearchParams(finalUrl.hash().slice(1));
    if (appState) hashParams.append("state", appState ?? "");
    if (sessionNamespace) hashParams.append("sessionNamespace", sessionNamespace);
    if (sessionId) {
      hashParams.append("b64Params", safebtoa(JSON.stringify({ sessionId })));
    } else if (error) {
      hashParams.append("error", error);
    }
    finalUrl.hash(hashParams.toString());
    setTimeout(() => {
      window.location.href = finalUrl.href();
    }, 50);
  }
}
