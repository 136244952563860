import { get } from "@toruslabs/http-helpers";
import {
  applyWhiteLabelTheme,
  AuthConnectionConfig,
  AuthConnectionConfigItem,
  LANGUAGE_MAP,
  type LANGUAGE_TYPE,
  LANGUAGES,
  type OriginData,
  type WhiteLabelData,
} from "@web3auth/auth";
import bowser from "bowser";
import log from "loglevel";
import URI from "urijs";

import useConfig from "@/composables/useConfig";
import { loadLanguageAsync } from "@/plugins/i18n-setup";

import { LOCAL_STORAGE_KEY, PASSKEYS_ALLOWED_MAP, STORAGE_TYPE } from "./enums";
import { CurrentOAuthLoginParams, DeviceInfo, SettingsPageData, ShareDetails } from "./interfaces";

const { config, network } = useConfig();

export function getBufferFromHexKey(hexString: string): Buffer {
  return Buffer.from(hexString.padStart(64, "0"), "hex");
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const prettyPrintData = (data: Record<string, unknown>): string => {
  let finalString = "";
  Object.keys(data).forEach((x) => {
    finalString = `${finalString}\n${x}: ${data[x]}`;
  });
  return finalString;
};

export function constructUrlParams(params: Record<string, string>): string {
  const localUrl = new URL("https://example.com");
  Object.keys(params).forEach((x) => {
    if (params[x]) localUrl.searchParams.append(x, params[x]);
  });
  return localUrl.searchParams.toString();
}

export const getIFrameOrigin = () => {
  const originHref = window.location.ancestorOrigins?.length > 0 ? window.location.ancestorOrigins[0] : document.referrer;
  if (!originHref) return originHref;
  const url = new URL(originHref);
  return url.origin;
};

export function validateOrigins(urlString: string, allowedOrigins: string[]): boolean {
  const url = new URI(urlString);
  return allowedOrigins.includes(url.origin());
}

export function validateInternalHost(
  urlString: string,
  allowedHostnames = ["localhost", "tor.us", "openlogin.com", "web3auth.io", "web3auth.vercel.app"]
): boolean {
  try {
    const url = new URI(urlString);
    let allowed = false;
    allowedHostnames.forEach((hostnameEnding) => {
      if (url.hostname().endsWith(hostnameEnding) && (url.scheme() === "https" || url.scheme() === "http")) allowed = true;
    });
    return allowed;
  } catch (error) {
    log.error(error, "could not validate url");
    return false;
  }
}

export function validateInternalHostExceptLocalhost(urlString: string): boolean {
  try {
    const allowedHostnameEndings = ["tor.us", "openlogin.com", "web3auth.io"];
    const url = new URI(urlString);
    let allowed = false;
    allowedHostnameEndings.forEach((hostnameEnding) => {
      if (url.hostname().endsWith(hostnameEnding) && url.scheme() === "https") allowed = true;
    });
    return allowed;
  } catch (error) {
    log.error(error, "could not validate url");
    return false;
  }
}

export function canPreserveState(_key: string, storage: STORAGE_TYPE): boolean {
  if (!config.value?.isStorageAvailable[storage]) return false;
  // return getStorage(storage)?.getItem(key) !== null;
  return false;
}

export function getStorage(key: STORAGE_TYPE): Storage | undefined {
  if (config.value?.isStorageAvailable[key]) return window[key];
  return undefined;
}

export function getAppLogo(isDark = false): string {
  return isDark ? "web3auth-wordmark-light.svg" : "web3auth-wordmark.svg";
}

export function getUserLanguage(): LANGUAGE_TYPE {
  const localStorageLanguage = getStorage(LOCAL_STORAGE_KEY)?.getItem("torus-locale");
  if (localStorageLanguage && Object.prototype.hasOwnProperty.call(LANGUAGE_MAP, localStorageLanguage)) return localStorageLanguage as LANGUAGE_TYPE;

  const { navigator } = window;
  let userLanguage = (navigator as unknown as { userLanguage: string }).userLanguage || navigator.language || "en-US";
  const userLanguageArr = userLanguage.split("-");
  userLanguage = Object.prototype.hasOwnProperty.call(LANGUAGE_MAP, userLanguageArr[0]) ? userLanguageArr[0] : LANGUAGES.en;
  return userLanguage as LANGUAGE_TYPE;
}

export function formatDate(date: string | Date): string {
  if (!date) return "";
  const toFormat = date instanceof Date ? date : new Date(date);
  const day = toFormat.getDate().toString().padStart(2, "0");
  const month = (toFormat.getMonth() + 1).toString().padStart(2, "0");
  const year = toFormat.getFullYear().toString().substring(2);
  return `${day}/${month}/${year}, ${toFormat.toLocaleString(undefined, { timeStyle: "short", hour12: false })}`;
}

export function getBrowserIcon(name: string): string {
  const BROWSER_ALIASES: Record<string, string> = {
    Chrome: "chrome",
    Chromium: "chrome",
    Firefox: "firefox",
    Safari: "safari",
    Brave: "brave",
    "Samsung Internet for Android": "android",
    "Microsoft Edge": "edge",
  };

  return BROWSER_ALIASES[name] ? `browser_${BROWSER_ALIASES[name]}` : "browser";
}

export function convertToMilliseconds(time: number) {
  if (time < 1e12) {
    return time * 1000; // Convert to milliseconds
  }
  return time;
}

export function getCustomDeviceInfo(deviceName = ""): DeviceInfo | undefined {
  const res: { browser?: string; deviceName?: string } = {};
  if ((navigator as unknown as { brave: boolean })?.brave) {
    res.browser = "Brave";
  }
  if (deviceName) res.deviceName = deviceName;
  if (Object.keys(res).length > 0) return res;
  return undefined;
}

export function setThemeClass(isDark: boolean) {
  const htmlElement = document.querySelector("html") as HTMLElement;
  if (htmlElement) {
    if (isDark) {
      htmlElement.classList.add("dark");
    } else {
      htmlElement.classList.remove("dark");
    }
  }
}

export function setTheme(whiteLabel: WhiteLabelData): void {
  log.info("modal: setTheme", JSON.stringify(whiteLabel || {}));

  let isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (whiteLabel && whiteLabel.mode) {
    isDark = whiteLabel.mode === "auto" ? isDark : whiteLabel.mode === "dark";
  }
  setThemeClass(isDark);

  const rootElement = document.querySelector(":root") as HTMLElement;
  if (whiteLabel?.theme && rootElement) {
    applyWhiteLabelTheme(rootElement, whiteLabel.theme);
  }

  if (whiteLabel?.defaultLanguage) loadLanguageAsync(whiteLabel.defaultLanguage);
}

/**
 * it will set the end mark for an operation and calculates the time between start and end of any operation
 * Note: make sure to set start marker for operation name (For ex: window.performance.mark(`${operationName}_start`);
 *
 * @param operationName - Name of the operation
 * @returns the time between start and end operation mark
 * before calling this function.
 */
export const measurePerformance = (operationName: string): number => {
  try {
    const measureName = `${operationName}_measure`;
    const startMarkName = `${operationName}_start`;
    const endMarkName = `${operationName}_end`;
    window.performance.mark(endMarkName);
    window.performance.measure(measureName, startMarkName, endMarkName);
    const perfEntry = window.performance.getEntriesByName(measureName);
    window.performance.clearMarks(startMarkName);
    window.performance.clearMarks(endMarkName);
    window.performance.clearMeasures(measureName);
    log.debug("time taken", perfEntry[0].duration, operationName);
    return perfEntry[0].duration;
  } catch (error) {
    // error might occur if start marker is not set before calling
    // this function
    log.error("Error in measurePerformance function", error);
    return 0;
  }
};

export const measurePerformanceAndRestart = (operationName: string): number => {
  try {
    const duration = measurePerformance(operationName);
    const startMarkName = `${operationName}_start`;
    window.performance.mark(startMarkName);
    log.debug("time taken and restarted", duration, operationName);
    return duration;
  } catch (error) {
    log.error("Error in measurePerformance function", error);
    return 0;
  }
};

export function getOSName(): string {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getOSName();
}

export const getUserCountry = async (): Promise<{ country: string; dialCode: string } | null> => {
  try {
    const result = await get<{ data: { country: string; dial_code: string } }>(`${config.value.passwordlessBackend}user/location`);
    if (result && result.data.country) return { country: result.data.country, dialCode: result.data.dial_code };
    return null;
  } catch (error) {
    log.error("error getting user country", error);
    return null;
  }
};

export const getWhitelist = async (clientId: string): Promise<OriginData> => {
  try {
    const url = new URL(`${config.value?.signerHost}/api/whitelist`);
    url.searchParams.append("project_id", clientId);
    url.searchParams.append("network", network.value ?? "");
    const res = await get<{ signed_urls: OriginData }>(url.href);
    return res.signed_urls;
  } catch (error: unknown) {
    // fail silently
    if (error instanceof Response) {
      log.error("error getting whitelist", (error as Response)?.status, JSON.stringify((error as Response)?.body));
    } else {
      log.error("error in whitelist", error);
    }
    return {};
  }
};

// export const fetchProjectConfig = async (clientId: string): Promise<PROJECT_CONFIG_RESPONSE> => {
//   try {
//     const url = new URL(`${config.value?.signerHost}/api/configuration`);
//     url.searchParams.append("project_id", clientId);
//     const res = await get<PROJECT_CONFIG_RESPONSE>(url.href);
//     return res;
//   } catch (e) {
//     throw new Error(`Failed to fetch project config: ${(e as Error).message}`);
//   }
// };

export const validateFeatureAccess = async (
  clientId: string,
  isWhitelabel: boolean,
  sessionTime: number,
  isCustomAuth: boolean,
  isMfaSettings: boolean
) => {
  const url = new URL(`${config.value?.signerHost}/api/feature-access`);
  url.searchParams.append("client_id", clientId);
  url.searchParams.append("network", network.value ?? "");
  url.searchParams.append("is_whitelabel", isWhitelabel.toString());
  url.searchParams.append("session_time", sessionTime.toString());
  url.searchParams.append("is_custom_auth", isCustomAuth.toString());
  url.searchParams.append("is_mfa_settings", isMfaSettings.toString());
  url.searchParams.append("enable_gating", "true");
  await get(url.href);
};

export const serializeError = async (error: unknown): Promise<Error> => {
  // Find first Error or create an "unknown" Error to keep stack trace.
  const isError = error instanceof Error;
  const isString = typeof error === "string";
  const isApiErrorIndex = error && typeof error === "object" && "status" in error && "type" in error;
  let err: Error;
  if (isApiErrorIndex) {
    const apiError = error as Response;
    log.debug("apiError", `${apiError.status} ${apiError.type.toString()} ${apiError.statusText}`);
    const contentType = apiError.headers.get("content-type");
    log.debug("contentType", contentType);
    if (contentType.includes("application/json")) {
      err = new Error(JSON.stringify(await apiError.json()));
    } else if (contentType.includes("text/plain")) {
      err = new Error(await apiError.text());
    } else {
      err = new Error(`${apiError.status} ${apiError.type.toString()} ${apiError.statusText}`);
    }
  } else if (isString) {
    err = new Error(error as string);
  } else if (isError) {
    err = error as Error;
  } else {
    err = new Error("Unknown error");
  }
  return err;
};

export function getHostNameForBackend(url: string) {
  const uri = new URI(url);
  if (uri.scheme() === "https" || uri.scheme() === "http") return uri.hostname();
  return uri.origin();
}

const getWindowsVersion = (osVersion: string) => {
  const windowsVersionRegex = /NT (\d+\.\d+)/;
  const match = osVersion.match(windowsVersionRegex);
  if (match) return parseInt(match[1], 10);
  return 0;
};

const checkIfOSIsSupported = (osName: string, osVersion: string) => {
  if (!PASSKEYS_ALLOWED_MAP.includes(osName)) return false;
  if (osName === bowser.OS_MAP.MacOS) return true;
  switch (osName) {
    case bowser.OS_MAP.iOS: {
      const version = parseInt(osVersion.split(".")[0], 10);
      return version >= 16;
    }
    case bowser.OS_MAP.Android: {
      const version = parseInt(osVersion.split(".")[0], 10);
      return version >= 9;
    }
    case bowser.OS_MAP.Windows: {
      const version = getWindowsVersion(osVersion);
      return version >= 10;
    }
    default:
      return false;
  }
};

export function shouldSupportPasskey(): { isBrowserSupported: boolean; isOsSupported: boolean; supportedBrowser?: Record<string, string> } {
  const browser = bowser.getParser(navigator.userAgent);
  const osDetails = browser.parseOS();
  if (!osDetails) return { isBrowserSupported: false, isOsSupported: false };
  const osName = osDetails.name;
  const result = checkIfOSIsSupported(osName, osDetails.version);
  if (!result) return { isBrowserSupported: false, isOsSupported: false };
  const browserData: Record<string, Record<string, string>> = {
    iOS: {
      safari: ">=16",
      chrome: ">=108",
    },
    macOS: {
      safari: ">=16",
      chrome: ">=108",
      firefox: ">=122",
    },
    Android: {
      chrome: ">=121",
    },
    Windows: {
      edge: ">=108",
      chrome: ">=108",
    },
  };
  const isBrowserSupported = browser.satisfies({ ...browserData });
  return { isBrowserSupported, isOsSupported: true, supportedBrowser: browserData[osName] };
}

export function checkPasskeyStatus() {
  if (!window?.PublicKeyCredential || typeof window.PublicKeyCredential === "function") return false;
  return shouldSupportPasskey().isBrowserSupported;
}

export const parseShareDetails = (settingsPageData: SettingsPageData): ShareDetails[] => {
  const { threshold } = settingsPageData || {};
  if (!threshold) return [];
  const totalShares = parseInt(threshold.split("/")[1] || "1", 10);
  if (totalShares < 2) return [];
  const shares: ShareDetails[] = [];
  if (settingsPageData.allDeviceShares.length > 0) {
    const lastShare = settingsPageData.allDeviceShares.sort((a, b) => b.rawDateAdded - a.rawDateAdded)[0];
    shares.push({ shareType: "device", details: lastShare.userAgent });
  }
  if (Object.keys(settingsPageData.exportedEmailShares).length > 0) {
    Object.values(settingsPageData.exportedEmailShares).forEach((emailShare) => {
      if (emailShare.email) {
        shares.push({ shareType: "seedPhrase", details: emailShare.email });
      }
    });
  }
  if (settingsPageData.socialShare.available) {
    shares.push({ shareType: "social", details: settingsPageData.socialShare.shares[0].verifierId });
  }

  if (settingsPageData.passwordShare.available) {
    shares.push({ shareType: "password", details: "" });
  }

  if (settingsPageData.authenticatorShare.available) {
    shares.push({ shareType: "authenticator", details: settingsPageData.authenticatorShare.shares[0].issuer });
  }

  if (settingsPageData.passkeyShare.available) {
    shares.push({ shareType: "passkey", details: "" });
  }

  return shares;
};

export const getFallbackVersion = () => {
  const { origin } = new URL(window.location.href);
  if (origin.includes("localhost")) return "development";
  if (origin.includes("testing")) return "testing";
  return null;
};

export const getLoginConfig = (authConnectionConfig: AuthConnectionConfig, loginParams: CurrentOAuthLoginParams) => {
  const { authConnection, groupedAuthConnectionId, authConnectionId } = loginParams;

  const loginConfig = authConnectionConfig.find((x: AuthConnectionConfigItem) => {
    if (groupedAuthConnectionId) {
      return x.authConnection === authConnection && x.groupedAuthConnectionId === groupedAuthConnectionId;
    }
    if (authConnectionId) {
      return x.authConnection === authConnection && x.authConnectionId === authConnectionId;
    }
    return x.authConnection === authConnection;
  });

  return loginConfig;
};
