import log from "loglevel";
import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/start",
    name: "Start",
    component: () => import("../views/Start"),
  },
  {
    path: "/frame",
    name: "Frame",
    component: () => import("../views/Frame"),
  },
  {
    path: "/callback",
    name: "Callback",
    component: () => import("../views/CallbackV2"),
  },
  {
    // this is the old callback page which is moved to a new route.
    // TODO: can think of a better name for this route.
    path: "/redirect/callback",
    name: "RedirectCallback",
    component: () => import("../views/Callback"),
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/RedirectAuth"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register"),
    meta: {
      rehydrate: true,
    },
  },
  {
    path: "/tkey-input",
    name: "TKeyInput",
    component: () => import("../views/TKeyInput"),
    meta: {
      rehydrate: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((guard) => {
  log.info(guard);
});

export default router;
