import { type AuthSessionConfig } from "@web3auth/auth";
import BN from "bn.js";
import log from "loglevel";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import useConfig from "@/composables/useConfig";
import { getAuthToken } from "@/rest/auth";
import { fetchLoginCount, getUser } from "@/rest/user";
import { SESSION_STORAGE_KEY, USER_MODULE_KEY } from "@/utils/enums";
import type { CustomAuthResult, ErrorTraceObj, IDBUser, IUserModuleState, KeyMode, TorusUserInfo, UserPayload } from "@/utils/interfaces";
import { cloneDeep, pickBy } from "@/utils/lodashUtils";
import { canPreserveState } from "@/utils/utils";
import store from "@/vuexStore";

import installStorePlugin from "../persistPlugin";

const { config, network: authNetwork } = useConfig();

const defaultTorusKey: CustomAuthResult = {
  keyData: {
    walletAddress: "",
    X: "",
    Y: "",
    privKey: "",
  },
  userInfo: {} as CustomAuthResult["userInfo"],
  finalKeyData: {
    walletAddress: "",
    X: "",
    Y: "",
    privKey: "",
  },
  metadata: {
    nonce: new BN(0),
    typeOfUser: "v2",
    upgraded: false,
    pubNonce: {
      X: "",
      Y: "",
    },
    serverTimeOffset: 0,
  },
  nodesData: { nodeIndexes: [] },
  oAuthKeyData: {
    walletAddress: "",
    privKey: "",
    X: "",
    Y: "",
  },
  sessionData: {
    sessionAuthKey: "",
    sessionTokenData: [],
  },
};

const constructSessionIdentifier = (loginId: string, sessionNamespace: string) => {
  if (sessionNamespace) return `${loginId}-${sessionNamespace}`;
  return loginId;
};

@Module({
  namespaced: true,
  name: USER_MODULE_KEY,
  store,
  dynamic: true,
  preserveState: canPreserveState(USER_MODULE_KEY, SESSION_STORAGE_KEY),
})
class UserModule extends VuexModule implements IUserModuleState {
  public customAuthInstanceId = "";

  // @SkipSync(USER_MODULE_KEY)
  public errorTraceMessageList: ErrorTraceObj[] = [];

  public userInfo: TorusUserInfo = {
    email: "",
    name: "",
    profileImage: "",
    groupedAuthConnectionId: "",
    authConnectionId: "",
    userId: "",
    authConnection: "",
  };

  public clientTimeOffset = 0;

  public keyInfo: CustomAuthResult = cloneDeep(defaultTorusKey);

  public walletKeyInfo: CustomAuthResult = cloneDeep(defaultTorusKey);

  public authToken = "";

  public challenge = "";

  public alwaysSkip = false;

  public localUserInfo: Partial<UserPayload> = {};

  public showTraceErrorModal = false;

  public toggleMobileDrawer = false;

  public loginId = "";

  public sessionNamespace = "";

  public sessionId = "";

  public keyMode: KeyMode = "v1";

  public tKeyPrivKey = "";

  public storageServerUrl: string = config.value.storageServerUrl;

  public finalPostboxKey: string = "";

  public dbUser: IDBUser | null = null;

  public cachedConfig: Record<string, { data: AuthSessionConfig; expiry: number }> = {};

  get walletKey(): string {
    return this.walletKeyInfo.finalKeyData.privKey || this.walletKeyInfo.oAuthKeyData.privKey;
  }

  get sessionSignatures(): string[] {
    return this.keyInfo.sessionData.sessionTokenData
      .filter((i) => Boolean(i))
      .map((session) => JSON.stringify({ data: session.token, sig: session.signature }));
  }

  @Mutation
  public setClientTimeOffset(offset: number) {
    this.clientTimeOffset = offset;
  }

  @Mutation
  public setKeyInfo(value: CustomAuthResult): void {
    this.keyInfo = value;
  }

  @Mutation
  public setWalletKeyInfo(value: CustomAuthResult): void {
    this.walletKeyInfo = value;
  }

  @Mutation
  public setUserInfo(value: TorusUserInfo): void {
    this.userInfo = value;
  }

  @Mutation
  public setAuthToken(value: string) {
    this.authToken = value;
  }

  @Mutation
  public setChallenge(value: string) {
    this.challenge = value;
  }

  @Mutation
  public setAlwaysSkip(value: boolean) {
    this.alwaysSkip = value;
  }

  @Mutation
  public setPostboxKey(value: string) {
    this.finalPostboxKey = value;
  }

  @Mutation
  public setCustomAuthInstanceId(value: string) {
    this.customAuthInstanceId = value;
  }

  @Mutation
  public logout() {
    this.userInfo = {
      email: "",
      name: "",
      profileImage: "",
      groupedAuthConnectionId: "",
      authConnectionId: "",
      userId: "",
      authConnection: "",
    };
    this.keyInfo = cloneDeep(defaultTorusKey);
    this.walletKeyInfo = cloneDeep(defaultTorusKey);
    this.authToken = "";
    this.localUserInfo = {};
    this.challenge = "";
    this.showTraceErrorModal = false;
    this.toggleMobileDrawer = false;
    this.sessionId = "";
    this.loginId = "";
    this.sessionNamespace = "";
    this.tKeyPrivKey = "";
    this.finalPostboxKey = "";
    this.storageServerUrl = config.value.storageServerUrl;
    this.customAuthInstanceId = "";
    this.cachedConfig = {};
  }

  @Mutation
  public setTraceErrorModal(isModalOpen: boolean) {
    this.showTraceErrorModal = isModalOpen;
  }

  @Mutation
  public setErrorTraceMessages(details: ErrorTraceObj[] | ErrorTraceObj) {
    this.errorTraceMessageList = Array.isArray(details) ? [...this.errorTraceMessageList, ...details] : [...this.errorTraceMessageList, details];
  }

  @Mutation
  public clearErrorTraceMessages() {
    this.errorTraceMessageList = [];
  }

  @Mutation
  public setMobileDrawer() {
    this.toggleMobileDrawer = !this.toggleMobileDrawer;
  }

  @Mutation
  public closeMobileDrawer() {
    this.toggleMobileDrawer = false;
  }

  @Mutation
  public setStorageServerUrl(value: string) {
    this.storageServerUrl = value;
  }

  @Mutation
  public setLoginConfig({ loginId, sessionNamespace, storageServerUrl }: { loginId: string; sessionNamespace?: string; storageServerUrl?: string }) {
    this.loginId = loginId;
    if (sessionNamespace) this.sessionNamespace = sessionNamespace;
    if (storageServerUrl) this.storageServerUrl = storageServerUrl;
  }

  @Mutation
  public setSessionId(sessionKey: string) {
    if (sessionKey) {
      this.sessionId = sessionKey;
    }
  }

  @Mutation
  public setKeyMode(mode: KeyMode) {
    this.keyMode = mode;
  }

  @Mutation
  public setTKeyPrivKey(privKey: string) {
    this.tKeyPrivKey = privKey;
  }

  @Mutation
  public setLocalUserInfo(payload: Partial<UserPayload>) {
    this.localUserInfo = { ...this.localUserInfo, ...payload };
  }

  @Mutation
  public setUser(user: IDBUser) {
    this.dbUser = user;
  }

  @Mutation
  public updateCacheLoginConfig({
    loginId,
    sessionNamespace,
    authSessionConfig,
  }: {
    loginId: string;
    sessionNamespace: string;
    authSessionConfig: AuthSessionConfig;
  }) {
    const identifier = constructSessionIdentifier(loginId, sessionNamespace);
    this.cachedConfig = {
      ...this.cachedConfig,
      [identifier]: { data: authSessionConfig, expiry: Math.floor(Date.now() / 1000) + 600 },
    };
  }

  @Mutation
  public resetCachedLoginConfig({ loginId, sessionNamespace }: { loginId: string; sessionNamespace: string }) {
    const identifier = constructSessionIdentifier(loginId, sessionNamespace);
    this.cachedConfig = {
      ...this.cachedConfig,
      [identifier]: undefined,
    };
  }

  @Action
  async getUser({ publicAddress }: { publicAddress: string }): Promise<IDBUser> {
    if (this.sessionSignatures) {
      const result = await getUser({
        public_address: publicAddress,
        signatures: this.sessionSignatures,
        network: authNetwork.value,
      });
      this.setUser(result);
      return result;
    }
    return null;
  }

  @Action
  async fetchLoginCount({ publicAddress }: { publicAddress: string }): Promise<number> {
    if (this.sessionSignatures) {
      const result = await fetchLoginCount({
        public_address: publicAddress,
        signatures: this.sessionSignatures,
        network: authNetwork.value,
      });
      // adding an extra record to the count
      // as we save the login count in the user's metadata in the end.
      return result + 1;
    }
    return 0;
  }

  @Action
  updateUserPersistedInfo(params: { payload: Partial<UserPayload> }) {
    const { payload } = params;
    const finalPayload = pickBy(payload, (val) => val !== null && val !== undefined && val !== "");
    this.setLocalUserInfo(finalPayload);
  }

  @Action
  async getAuthToken() {
    const params = { public_address: this.keyInfo.keyData.walletAddress, signatures: this.sessionSignatures, network: authNetwork.value };
    const token = await getAuthToken(params);
    if (token) this.setAuthToken(token);
  }

  @Action
  async getLoginConfig({ loginId, sessionNamespace }: { loginId: string; sessionNamespace: string }) {
    const identifier = constructSessionIdentifier(loginId, sessionNamespace);
    if (this.cachedConfig[identifier]) {
      if (this.cachedConfig[identifier].expiry > Math.floor(Date.now() / 1000)) {
        return this.cachedConfig[identifier].data;
      }
      this.resetCachedLoginConfig({ loginId, sessionNamespace });
    }
    return null;
  }
}

const userModule = getModule(UserModule);

installStorePlugin({
  key: USER_MODULE_KEY,
  storage: SESSION_STORAGE_KEY,
  saveState: (key: string, state: Record<string, unknown>, storage?: Storage) => {
    try {
      storage?.setItem(key, JSON.stringify(state));
    } catch (error) {
      log.error(error);
    }
  },
  restoreState: (key: string, storage?: Storage) => {
    const value = storage?.getItem(key);
    if (typeof value === "string") {
      // If string, parse, or else, just return
      const parsedValue = JSON.parse(value || "{}");
      delete parsedValue[USER_MODULE_KEY]?.toastMessage;
      return {
        [USER_MODULE_KEY]: parsedValue[USER_MODULE_KEY],
      };
    }
    return value || {};
  },
});

export default userModule;
