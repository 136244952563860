import type * as SentryType from "@sentry/browser";
import type { Client } from "@sentry/types";
import { enableSentryTracing } from "@toruslabs/http-helpers";
import type { WEB3AUTH_NETWORK_TYPE } from "@web3auth/auth";
import log from "loglevel";

import useConfig from "@/composables/useConfig";

const { config, environment, metadataHost } = useConfig();

let sentryClient: Client | undefined;
let sentryInstance: typeof SentryType | undefined;

async function enableSentryLogging() {
  if (!sentryInstance) return;
  const { LoglevelSentry } = await import("@toruslabs/loglevel-sentry");
  const plugin = new LoglevelSentry(sentryInstance);
  plugin.install(log);
}

export async function installSentry(): Promise<void> {
  if (!import.meta.env.VITE_SENTRY_DSN) return;
  if (sentryClient) return;

  sentryClient = sentryInstance.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment,
    release: `auth-service@${config.value?.version}`,

    integrations: [
      sentryInstance.browserTracingIntegration(),
      sentryInstance.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [
          "https://node-1.node.web3auth.io",
          "https://node-2.node.web3auth.io",
          "https://node-3.node.web3auth.io",
          "https://node-4.node.web3auth.io",
          "https://node-5.node.web3auth.io",
          "https://api.web3auth.io",
        ],
        networkRequestHeaders: [],
        networkResponseHeaders: ["X-Web3-Correlation-Id"],
      }),
    ],
    ignoreErrors: [
      // Happen when user click 'X' on the browser (ref https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2)
      // "TypeError: Failed to fetch", // All except iOS and Firefox
      // "Failed to fetch", // All except iOS and Firefox
      // "TypeError: cancelled", // iOS
      // "TypeError: NetworkError when attempting to fetch resource.", // Firefox
      "Cannot redefine property: ethereum",
    ],
    sampleRate: Number.parseFloat(config.value?.sentrySampleRate),
    tracesSampleRate: Number.parseFloat(config.value?.sentryTransactionSampleRate),
    tracePropagationTargets: [
      "https://node-1.node.web3auth.io",
      "https://node-2.node.web3auth.io",
      "https://node-3.node.web3auth.io",
      "https://node-4.node.web3auth.io",
      "https://node-5.node.web3auth.io",
      "https://api.web3auth.io",
    ],
    normalizeDepth: 8,
    // beforeBreadcrumb(breadcrumb) {
    //   breadcrumb.data = redactBreadcrumbData(breadcrumb.data);
    //   return breadcrumb;
    // },
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    sendDefaultPii: true,
  });
  enableSentryLogging();
  enableSentryTracing(sentryInstance, [config.value.signerHost, config.value.apiHost, metadataHost.value], ["/jrpc", "/"]);
}

// TODO: add mutex lock here.
export async function getSentryInstance({ clientId, network }: { clientId?: string; network?: WEB3AUTH_NETWORK_TYPE } = {}): Promise<
  typeof SentryType
> {
  if (!sentryInstance) {
    sentryInstance = await import("@sentry/browser");
    if (!sentryClient) await installSentry();
  }

  if (clientId) sentryInstance.setTag("dapp_client_id", clientId);
  if (network) sentryInstance.setTag("auth_network", network);
  return sentryInstance;
}

export async function setSentryEnabled(enabled: boolean): Promise<void> {
  if (!sentryClient) return;

  sentryClient.getOptions().enabled = enabled;
}
