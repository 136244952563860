import { type KeyDetails, type ShareStore, type StringifiedType } from "@tkey/common-types";
import type { TKey } from "@tkey/core";
import { type TorusKey } from "@toruslabs/torus.js";
import type {
  AUTH_ACTIONS_TYPE,
  AUTH_CONNECTION_TYPE,
  AuthConnectionConfig,
  AuthSessionData,
  CUSTOM_AUTH_CONNECTION_TYPE,
  LoginParams,
  LoginWindowResponse,
  MFA_FACTOR,
  MFA_FACTOR_TYPE,
  MFA_SETTINGS,
  MfaLevelType,
  MfaSettings,
  OriginData,
  TorusConnectionResponse,
  UX_MODE_TYPE,
  WEB3AUTH_NETWORK_TYPE,
  WhiteLabelData,
} from "@web3auth/auth";
import { type LogLevelDesc } from "loglevel";
import { type CustomError } from "ts-custom-error";

import { AUTH_FACTOR_TYPE, EXTERNAL_AUTH_TOKEN, LOGIN_ACTION_FLOW, LOGIN_FLOW, STORAGE_TYPE, TOAST_TYPES } from "./enums";

export type DeviceInfo = {
  browser?: string;
  deviceName?: string;
};

export type LOGIN_FLOW_TYPE = (typeof LOGIN_FLOW)[keyof typeof LOGIN_FLOW];
export type ShareDetails = {
  shareType: string;
  details?: string;
};

export type AuthSession = AuthSessionData & { shareDetails?: ShareDetails[] };

export type CreateNewTKeyParams = {
  postboxKey: string;
  importKey?: string;
  password?: string;
  backup?: boolean;
  recoveryEmail?: string;
  authConnection: string;
  userId: string;
  syncLocalTransitions?: boolean;
  customDeviceInfo?: DeviceInfo;
};

export type ResponsePayload = {
  response?: string;
  rejected?: string;
};

export type ModuleShareDescription = { [x: string]: string | boolean | number | string[]; available: boolean; shareIndex: string; module: string };
export type LOGIN_ACTION_FLOW_TYPE = (typeof LOGIN_ACTION_FLOW)[keyof typeof LOGIN_ACTION_FLOW];

export type DeviceShareDescription = {
  index: string;
  indexShort: string;
  osDetails: string;
  icon?: string;
  title: string;
  dateAdded: string;
  module: string;
  userAgent: string;
  rawDateAdded?: number;
  customDeviceInfo?: DeviceInfo;
};

export type AllDeviceShares = DeviceShareDescription[];

export type OnDeviceShare = {
  available: boolean;
  share?: ShareStore;
};

export type EmailShareDescription = { email: string; index: string; indexShort: string; dateAdded: string };

export type ShareSerializationEmailShares = {
  [index: string]: EmailShareDescription;
};

export type PasswordShare = {
  available: boolean;
};

export type SocialShareDescription = {
  shareIndex: string;
  dateAdded: string;
  dateAddedDisplay: string;
  loginProvider: string;
  verifierId: string;
  icon?: string; // added later in the components.
};

export type SocialShareSetting = {
  available: boolean;
  shares?: SocialShareDescription[];
};

export type PasskeyShareDescription = {
  shareIndex: string;
  dateAdded: string;
  dateAddedDisplay: string;
  username: string;
  verifierId?: string;
  authenticatorTransport?: string;
};

export type AuthenticatorShareDescription = {
  shareIndex: string;
  dateAdded: string;
  dateAddedDisplay: string;
  issuer?: string;
};

export type PasskeyShareSetting = {
  available: boolean;
  shares?: PasskeyShareDescription[];
};

export type AuthenticatorShareSetting = {
  available: boolean;
  shares?: AuthenticatorShareDescription[];
};

export type OtherShares = string[];

export type SettingsPageData = {
  deviceShare: OnDeviceShare;
  allDeviceShares: AllDeviceShares;
  exportedEmailShares: ShareSerializationEmailShares;
  otherShares: OtherShares;
  passwordShare: PasswordShare;
  socialShare: SocialShareSetting;
  passkeyShare: PasskeyShareSetting;
  authenticatorShare: AuthenticatorShareSetting;
  threshold: string;
};

export interface TKeyAccount {
  privKey: string;
}

export interface CalculateSettingsPageParams {
  forceCheckOnDeviceShare?: boolean;
}

export type KeyMode = "v1" | "1/1" | "2/n";

export type UserType = "v1" | "v2";

export type LoginType = KeyMode;

export type TorusKeyPub = {
  pubKey?: {
    pub_key_X: string;
    pub_key_Y: string;
  };
};

export type CustomAuthResult = {
  /** This is the postboxKey Data */
  keyData: TorusKey["oAuthKeyData"];
  userInfo: TorusConnectionResponse & LoginWindowResponse;
} & Omit<TorusKey, "postboxKeyData">;

export type TorusUserInfo = {
  email: string;
  name: string;
  profileImage: string;
  groupedAuthConnectionId: string;
  authConnectionId: string;
  userId: string;
  authConnection: AUTH_CONNECTION_TYPE | CUSTOM_AUTH_CONNECTION_TYPE;
  idToken?: string;
  accessToken?: string;
};

export type TkeyInputParams = {
  postboxKey: string;
  importKey?: string;
  tKeyJson?: StringifiedType;
  shareStores: ShareStore[];
  serverTimeOffset?: number;
  dappShare?: string;
};

export type ShareRequestInfo = {
  shortIndex: string;
  timestamp: string;
  browserName: string;
  platformType: string;
  encPubKeyX: string;
  userIp?: string;
};

export const SUPPORTED_KEY_CURVES = {
  SECP256K1: "secp256k1",
  ED25519: "ed25519",
  OTHER: "other",
} as const;

export type SUPPORTED_KEY_CURVES_TYPE = (typeof SUPPORTED_KEY_CURVES)[keyof typeof SUPPORTED_KEY_CURVES];

export type ToastMessage = {
  type?: (typeof TOAST_TYPES)[keyof typeof TOAST_TYPES];
  message: string;
};

export type LoginRecordUpdate = {
  login_record_id: string;
  error_stack?: string;
  has_skipped_tkey?: boolean;
  login_route: string;
  time_taken: number;
};

export type UserUpdatePayload = {
  tkey_public_address?: string;
  tkey_threshold?: number;
  tkey_password?: boolean;
  tkey_backup_emails?: number;
  tkey_creation_factor?: string;
  theme?: string;
  locale?: string;
  always_skip_tkey?: boolean;
};

export type DeviceUpdatePayload = {
  device_id?: string;
  share_index?: string;
};

export type UserLoginPayload = {
  client_id: string;
  hostname: string;
  time_taken: number;
  share_index?: string;
  error_stack?: string;
  login_route: string;
  is_fast_login?: boolean;
  has_skipped_tkey?: boolean;
  browser: string;
  browser_version: string;
  os: string;
  os_version: string;
  platform: string;
  origin: string;
};

export type UserLoginUpdatePayload = {
  login_record_id: string;
  time_taken: number;
  login_route: string;
  error_stack?: string;
  has_skipped_tkey?: boolean;
  browser?: string;
  browser_version?: string;
  os?: string;
  os_version?: string;
  platform?: string;
};

export type UserRegisterPayload = {
  client_id: string;
  hostname: string;
  verifier: string;
  verifier_id: string;
  tkey_public_address?: string;
  tkey_threshold?: number;
  tkey_password?: boolean;
  tkey_backup_emails?: number;
  tkey_creation_factor?: string;
  theme?: string;
  locale?: string;
};

export type AppListItem = {
  dappId?: number;
  clientId: string;
  appName: string;
  appUrl: string;
  details: string;
  image: string;
  walletChains?: Array<string>;
};

export type MFA_FACTOR_ITEM = Partial<MFA_SETTINGS> & { key: string; title: string; description: string; icon: string; routeName: string };

export type RegisterMfaData = {
  // Device factor
  [MFA_FACTOR.DEVICE]: {
    completed?: boolean;
    deviceName?: string;
    skipped?: boolean;
  };
  // Recovery factor
  [MFA_FACTOR.BACKUP_SHARE]: {
    completed?: boolean;
    recoveryShareIndex?: string;
    backupToken?: string;
    backupTokenDate?: string;
    backupTokenCopied?: boolean;
    backupTokenDownloaded?: boolean;
    recoveryEmail?: string;
    skipped?: boolean;
  };
  // Password factor
  [MFA_FACTOR.PASSWORD]: {
    completed?: boolean;
    password?: string;
    skipped?: boolean;
  };
  // Social factor
  [MFA_FACTOR.SOCIAL_BACKUP]: {
    completed?: boolean;
    privateKey?: string;
    authConnection?: string;
    userId?: string;
    // since we changed the privateKey to be used, we use this backup to check with both privateKey and backupPrivateKey
    backupPrivateKey?: string;
    skipped?: boolean;
  };
  [MFA_FACTOR.AUTHENTICATOR]?: {
    completed?: boolean;
    privateKey?: string;
    skipped?: boolean;
    issuer?: string;
  };
  [MFA_FACTOR.PASSKEYS]?: {
    completed?: boolean;
    privateKey?: string;
    authenticatorTransport?: string[];
    username?: string;
    userId?: string;
    skipped?: boolean;
  };
};

export type ErrorTraceObj = {
  title?: string;
  createdAt?: string;
  errorMsg: any;
  errorStack?: any;
  info?: { [key: string]: any };
};

export interface BaseStateParams {
  client: string;
  popupWindow: string;
  keyMode: string;
  whiteLabel: string;
  disableAlwaysSkip: string;
  loginId: string;
  version: string;
  sessionNamespace: string;
  // added by customauth sdk.
  /** auth connection for generic social login */
  instanceId: string;
  /** auth connection for generic social login */
  authConnection: string;
  /** auth connection id for generic social login */
  authConnectionId: string;
  /** grouped auth connection id for generic social login */
  groupedAuthConnectionId: string;
}

export interface CustomAuthStateParams extends BaseStateParams {
  socialFactorFlow: string;
  loginFlow?: string;
  issuer?: string;
}

export interface PasskeysCustomAuthStateParams extends BaseStateParams {
  authenticatorAttachment: string;
  oAuthUserId: string;
  oAuthConnectionId: string;
  authToken: string;
  address: string;
  dappUrl: string;
  dappName: string;
  isCustomAuthConnectionId: string;
  loginFlow: LOGIN_FLOW_TYPE;
  passkeysHostUrl?: string;
  oAuthConnection: string;
  email: string;
  name: string;
  network: WEB3AUTH_NETWORK_TYPE;
}

export type GenericCustomAuthParams = CustomAuthStateParams;

// These params are added to the state by the customauth sdk.
export type AdditionalCustomAuthResponseParams = {
  authConnectionId: string;
  groupedAuthConnectionId: string;
  authConnection: string;
  instanceId: string;
};

export type CustomAuthStateResponse<T = GenericCustomAuthParams> = T & AdditionalCustomAuthResponseParams;

export type FactorListType = {
  enable?: boolean;
  priority: number;
  name: string;
}[];

export interface AppConfig {
  version: string;
  appVersion: number;
  signerHost: string;
  apiHost: string;
  passwordlessBackend: string;
  passwordlessHost: string;
  storageServerUrl: string;
  tkeyEmail: {
    host: string;
    logo: string;
    name: string;
  };
  prodNetworks: string[];
  alwaysAllowedHosts: string[];
  allowedDashboardHosts: string[];
  allowedDashboardOrigins: string[];
  supportsVersioning: boolean;
  redirectPath: string;
  sentrySampleRate: string;
  sentryTransactionSampleRate: string;
  isStorageAvailable: Record<STORAGE_TYPE, boolean>;
  mfaSettings: MfaSettings;
  logLevel: LogLevelDesc;
  authConnectionConfig: AuthConnectionConfig;
}

export interface PROJECT_CONFIG_RESPONSE {
  whitelabel?: WhiteLabelData;
  sms_otp_enabled: boolean;
  wallet_connect_enabled: boolean;
  wallet_connect_project_id?: string;
}

export interface IAuthServiceError extends CustomError {
  code: number;
  message: string;
  toString(): string;
}

export type ErrorCodes = {
  [key: number]: string;
};

export type ErrorAction = { buttonText: string; isPrimary: boolean; link: string };

export type UserLoginRecord = {
  browser: string;
  browser_version: string;
  device_id?: string;
  error_stack?: string;
  factors_used?: string;
  has_skipped_mfa?: boolean;
  has_skipped_tkey?: boolean;
  is_fast_login?: boolean;
  login_route: string;
  login_type?: string;
  mfa_level?: string;
  mobile_origin?: string;
  os: string;
  os_version: string;
  platform?: string;
  session_pub_key?: string;
  share_index?: string;
  time_taken: number;
  webauthn_available: boolean;
  webauthn_enabled?: boolean;
  metadata: string;
  app_version: number | null;
};

export type UserPayload = {
  always_skip_tkey?: boolean;
  backup_phrase_setup_at?: string;
  backup_phrase_setup_email?: string;
  locale?: string;
  mfa_time?: number;
  social_factor_login_provider?: string;
  social_factor_verifier_id?: string;
  theme?: string;
  tkey_backup_copied?: boolean;
  tkey_backup_downloaded?: boolean;
  tkey_backup_emails?: number;
  tkey_backup_verified?: boolean;
  tkey_creation_factor?: string;
  tkey_password?: boolean;
  tkey_public_address?: string;
  tkey_threshold?: number;
  user_type?: string;
  v2_wallet_key_enabled?: boolean;
  wallet_public_address?: string;
  verifier: string;
  verifier_id: string;
};

export interface IDBUser {
  public_address: string;
  verifier: string;
  verifier_id: string;
  tkey_public_address: string | null;
  wallet_public_address: string | null;
  tkey_threshold: number | null;
  tkey_password: boolean | null;
  tkey_backup_emails: number | null;
  tkey_creation_factor: string | null;
  theme: string;
  locale: string;
  always_skip_tkey: boolean | null;
  v2_wallet_key_enabled: boolean | null;
  user_type: string | null;
  mfa_time: number | null;
  tkey_backup_downloaded: boolean | null;
  tkey_backup_copied: boolean | null;
  tkey_backup_verified: boolean | null;
  origin: string | null;
  backup_phrase_setup_at: Date | null;
  backup_phrase_setup_email: string | null;
  social_factor_verifier_id: string | null;
  social_factor_login_provider: string | null;
  app_scoped_public_address_ethereum: string | null;
  app_scoped_public_address_solana: string | null;
}

export type SaveLoginSessionMetadata = {
  app_scoped_public_address_ethereum?: string;
  app_scoped_public_address_solana?: string;
  client_id: string;
  dapp_public_key?: string;
  hostname: string;
  login_record: UserLoginRecord;
  network: string;
  public_address: string;
  signatures?: string[];
  user: UserPayload;
  wallet_public_address?: string;
};

export interface IUserModuleState {
  errorTraceMessageList: ErrorTraceObj[];
  userInfo: TorusUserInfo;
  clientTimeOffset: number;
  keyInfo: CustomAuthResult;
  walletKeyInfo: CustomAuthResult;
  authToken: string;
  challenge: string;
  alwaysSkip: boolean;
  localUserInfo: Partial<UserPayload>;
  showTraceErrorModal: boolean;
  toggleMobileDrawer: boolean;
  loginId: string;
  sessionNamespace: string;
  sessionId: string;
  keyMode: KeyMode;
  tKeyPrivKey: string;
  finalPostboxKey: string;
  walletKey: string;
  sessionSignatures: string[];
  customAuthInstanceId: string;
  cachedConfig: Record<string, { data: AuthSessionData; expiry: number }>;
}

export interface ITkeyModuleState {
  tKey: TKey;
  postboxKey: string;
  settingsPageData: SettingsPageData;
  parsedShareDescriptions: unknown[];
  keyDetails: KeyDetails;
  error?: string;
  success?: string;
  currentEncPubKeyX?: string;
  initialized: boolean;
  registerMfaData: RegisterMfaData;
  creationFactor: string;
}

export interface ILoginPerfModuleState {
  currentLoginPath: string;
  lastRoute: string;
  totalTimeTaken: number;
  totalMfaTimeTaken: number;
  loginRecordId: string;
  dappId: number;
  authFactorsUsed: AUTH_FACTOR_TYPE[];
}

export type CurrentOAuthLoginParams = Pick<LoginParams, "authConnection" | "authConnectionId" | "groupedAuthConnectionId" | "extraLoginOptions">;
export interface IDappModuleState {
  originData: OriginData;
  dappShare: string;
  clientId: string;
  redirectUrl: string;
  customAuthConnectionConfig: AuthConnectionConfig;
  currentOAuthLoginParams: CurrentOAuthLoginParams;
  mfaLevel: MfaLevelType;
  getWalletKey: boolean;
  appState: string;
  curve: SUPPORTED_KEY_CURVES_TYPE;
  siteMetadata: {
    icon: string;
    name: string;
    url: string;
    date: Date;
  };
  whiteLabel: WhiteLabelData;
  sessionTime: number;
  mfaSettings: MfaSettings;
  storageServerUrl: string;
  uxMode: UX_MODE_TYPE;
  actionType: AUTH_ACTIONS_TYPE;
  whiteLabelLoaded: boolean;
  isDarkMode: boolean;
  useLogoLoader: boolean;
  isCustomAuthConnectionId: boolean;
  canSendDappShare: boolean;
  isAuthDashboard: boolean;
  authConnectionConfig: AuthConnectionConfig;
  rehydrated: boolean;
  useCoreKitKey: boolean;
}

export type EXTERNAL_AUTH_TOKEN_TYPE = (typeof EXTERNAL_AUTH_TOKEN)[keyof typeof EXTERNAL_AUTH_TOKEN];

export type ExternalAuthTokenKey = {
  curve: SUPPORTED_KEY_CURVES_TYPE;
  pub_key: string;
  signature: string;
  type: EXTERNAL_AUTH_TOKEN_TYPE;
};

export type ExternalAuthTokenPayload = {
  client_id: string;
  timeout?: number;
  curve: string;
  email?: string;
  profile_image?: string;
  name?: string;
  verifier?: string;
  aggregate_verifier?: string;
  type_of_login?: string;
  verifier_id?: string;
  oauth_public_key?: string;
  session_nonce?: string;
  network: string;
  signatures: string[];
  public_address: string;
  keys: ExternalAuthTokenKey[];
};

export type MFA_SETTINGS_UI = Partial<MFA_SETTINGS> & { done: boolean; factor: MFA_FACTOR_TYPE; mandatory?: boolean; doneDescription?: string };
