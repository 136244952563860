import { privateToPublic, pubToAddress } from "@ethereumjs/util";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { SessionManager } from "@toruslabs/session-manager";
import {
  AUTH_ACTIONS,
  AUTH_ACTIONS_TYPE,
  AuthConnectionConfig,
  MFA_LEVELS,
  type MfaLevelType,
  type MfaSettings,
  OriginData,
  SDK_MODE,
  SDK_MODE_TYPE,
  UX_MODE,
  type UX_MODE_TYPE,
  type WhiteLabelData,
} from "@web3auth/auth";
import BN from "bn.js";
import bowser from "bowser";
import bs58 from "bs58";
import deepmerge from "deepmerge";
import log from "loglevel";
import URI from "urijs";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

import useConfig from "@/composables/useConfig";
import { getExternalAuthToken, saveLoginSessionMetadata } from "@/rest/dapp";
import { DAPP_MODULES_STORE_KEY, ERROR_MISSING_PARAMS, EXTERNAL_AUTH_TOKEN, PREFERENCES_OP, TKEY_MODULE_KEY, USER_MODULE_KEY } from "@/utils/enums";
import {
  AuthSession,
  CurrentOAuthLoginParams,
  ExternalAuthTokenKey,
  IDappModuleState,
  ITkeyModuleState,
  IUserModuleState,
  LoginType,
  SaveLoginSessionMetadata,
  SUPPORTED_KEY_CURVES,
  SUPPORTED_KEY_CURVES_TYPE,
} from "@/utils/interfaces";
import { cloneDeep } from "@/utils/lodashUtils";
import { redirectToDapp } from "@/utils/redirect";
import { getSentryInstance } from "@/utils/sentry";
import { hashMessage, signMessage } from "@/utils/signMessage";
import { getBufferFromHexKey, getCustomDeviceInfo, getHostNameForBackend, parseShareDetails, setTheme, validateOrigins } from "@/utils/utils";
import store from "@/vuexStore";

import loginPerfModule from "./loginPerf";
import userModule from "./user";

const { config, authVerifiers, network } = useConfig();

@Module({
  namespaced: true,
  name: DAPP_MODULES_STORE_KEY,
  store,
  dynamic: true,
  preserveState: false,
})
export class DappModule extends VuexModule implements IDappModuleState {
  storageServerUrl: string;

  useCoreKitKey: boolean = false;

  rehydrated: boolean = false;

  originData: OriginData = {};

  dappShare = "";

  clientId = "";

  /**
   * WARNING: do not set redirectUrl lightly, this should only be set after validations (eg. middleware)
   */
  redirectUrl: string = "";

  customAuthConnectionConfig: AuthConnectionConfig = [];

  mfaLevel: MfaLevelType = MFA_LEVELS.NONE;

  getWalletKey = false;

  appState = "";

  curve: SUPPORTED_KEY_CURVES_TYPE = SUPPORTED_KEY_CURVES.SECP256K1;

  siteMetadata = {
    icon: "",
    name: "",
    url: "",
    date: new Date(),
  };

  whiteLabel: WhiteLabelData = { mode: "light" };

  sessionTime = 86400; // seconds

  mfaSettings: MfaSettings = cloneDeep(config.value.mfaSettings);

  uxMode: UX_MODE_TYPE = UX_MODE.REDIRECT;

  actionType: AUTH_ACTIONS_TYPE = AUTH_ACTIONS.LOGIN;

  whiteLabelLoaded = false;

  sdkMode: SDK_MODE_TYPE = SDK_MODE.DEFAULT;

  currentOAuthLoginParams: CurrentOAuthLoginParams | null = null;

  get authConnectionConfig(): AuthConnectionConfig {
    const localLoginConfig = cloneDeep(config.value?.authConnectionConfig);
    const finalConfig = localLoginConfig.concat(this.customAuthConnectionConfig);
    return finalConfig;
  }

  get isAuthDashboard(): boolean {
    return validateOrigins(this.redirectUrl, config.value?.allowedDashboardOrigins);
  }

  get canSendDappShare(): boolean {
    const { tKeyPrivKey, keyMode, userInfo } = this.context.rootState[USER_MODULE_KEY] as IUserModuleState;
    const { groupedAuthConnectionId, authConnectionId } = userInfo;
    const customVerifier = groupedAuthConnectionId || authConnectionId;
    // check if tkey exists (!tkeyPrivKey)
    if (!tKeyPrivKey || keyMode === "1/1") return false;
    return !authVerifiers.value.includes(customVerifier) || this.isAuthDashboard;
  }

  get isCustomAuthConnectionId(): boolean {
    const currentConfig = this.currentOAuthLoginParams;
    if (!currentConfig || !currentConfig.authConnectionId) return false;
    if (authVerifiers.value.includes(currentConfig.groupedAuthConnectionId || currentConfig.authConnectionId)) {
      return false;
    }
    return true;
  }

  get isDarkMode(): boolean {
    let isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (this.whiteLabel && this.whiteLabel.mode) {
      isDark = this.whiteLabel.mode === "auto" ? window.matchMedia("(prefers-color-scheme: dark)").matches : this.whiteLabel.mode === "dark";
    }
    return isDark;
  }

  get useLogoLoader(): boolean {
    return this.whiteLabel?.useLogoLoader || false;
  }

  get issuerName(): string {
    if (this.isCustomAuthConnectionId && this.siteMetadata.name) return `${this.siteMetadata.name}-${userModule.userInfo.userId}`;
    return `Web3Auth-${userModule.userInfo.userId}`;
  }

  @Mutation
  public setSiteMetadata(params: { icon?: string; name: string; url: string }): void {
    const { icon, name, url } = params;
    this.siteMetadata = {
      icon: icon || this.siteMetadata.icon || "",
      name: name || this.siteMetadata.name,
      url: url || this.siteMetadata.url,
      date: new Date(),
    };
  }

  @Mutation
  public setDappParams(params: {
    clientId?: string;
    redirectUrl?: string;
    actionType?: AUTH_ACTIONS_TYPE;
    originData?: OriginData;
    uxMode?: UX_MODE_TYPE;
    sessionTime?: number;
    rehydrated?: boolean;
    useCoreKitKey?: boolean;
    sdkMode?: SDK_MODE_TYPE;
    customAuthConnectionConfig?: AuthConnectionConfig;
  }): void {
    const { clientId, originData, redirectUrl, uxMode, sessionTime, actionType, rehydrated, useCoreKitKey, sdkMode, customAuthConnectionConfig } =
      params;
    // This will throw if not valid url
    try {
      if (redirectUrl) this.redirectUrl = new URI(redirectUrl).href();
    } catch (error) {
      log.error(error, ERROR_MISSING_PARAMS);
      throw new Error(ERROR_MISSING_PARAMS);
    }
    if (clientId) this.clientId = clientId;
    if (customAuthConnectionConfig) this.customAuthConnectionConfig = customAuthConnectionConfig;
    if (sessionTime) this.sessionTime = sessionTime;
    if (originData !== undefined) this.originData = originData;
    if (uxMode !== undefined) this.uxMode = uxMode;
    if (actionType) this.actionType = actionType;
    if (typeof rehydrated !== "undefined") this.rehydrated = rehydrated;
    if (typeof useCoreKitKey === "boolean") this.useCoreKitKey = useCoreKitKey;
    if (sdkMode) this.sdkMode = sdkMode;

    log.info(
      {
        clientId: this.clientId,
        sessionTime: this.sessionTime,
        originData: this.originData,
        uxMode: this.uxMode,
        redirectUrl: this.redirectUrl,
        actionType: this.actionType,
        rehydrated: this.rehydrated,
        useCoreKitKey: this.useCoreKitKey,
        sdkMode: this.sdkMode,
      },
      "current dapp params"
    );
  }

  @Mutation
  public setLoginParams(params: {
    currentOAuthLoginParams: CurrentOAuthLoginParams;
    getWalletKey?: boolean;
    mfaLevel?: MfaLevelType;
    appState?: string;
    dappShare?: string;
    curve?: string;
  }): void {
    const { currentOAuthLoginParams, getWalletKey, mfaLevel, appState, dappShare, curve } = params;
    if (currentOAuthLoginParams) this.currentOAuthLoginParams = currentOAuthLoginParams;
    if (getWalletKey !== undefined) this.getWalletKey = getWalletKey;
    if (mfaLevel) this.mfaLevel = mfaLevel;
    if (appState) this.appState = appState;
    if (dappShare) this.dappShare = dappShare;
    if (curve) this.curve = curve as SUPPORTED_KEY_CURVES_TYPE;

    log.info(
      {
        currentOAuthLoginParams: this.currentOAuthLoginParams,
        getWalletKey: this.getWalletKey,
        mfaLevel: this.mfaLevel,
        appState: this.appState,
        dappShare: this.dappShare,
        curve: this.curve,
      },
      "current login params"
    );
  }

  @Mutation
  updateState(state: Partial<DappModule>): void {
    const {
      customAuthConnectionConfig,
      clientId,
      redirectUrl,
      whiteLabel,
      sessionTime,
      dappShare,
      mfaSettings,
      originData,
      uxMode,
      whiteLabelLoaded,
      rehydrated,
    } = state;
    if (customAuthConnectionConfig !== undefined) this.customAuthConnectionConfig = customAuthConnectionConfig;
    if (clientId !== undefined) this.clientId = clientId;
    if (redirectUrl !== undefined) this.redirectUrl = redirectUrl;
    if (whiteLabel !== undefined) this.whiteLabel = whiteLabel;
    if (sessionTime !== undefined) this.sessionTime = sessionTime;
    if (dappShare !== undefined) this.dappShare = dappShare;
    if (mfaSettings !== undefined) this.mfaSettings = mfaSettings;
    if (originData !== undefined) this.originData = originData;
    if (uxMode !== undefined) this.uxMode = uxMode;
    if (whiteLabelLoaded !== undefined) this.whiteLabelLoaded = whiteLabelLoaded;
    if (rehydrated !== undefined) this.rehydrated = rehydrated;
  }

  @Action
  public setWhiteLabel(whiteLabel: WhiteLabelData): void {
    setTheme(whiteLabel);
    this.context.commit(`updateState`, { whiteLabel });
  }

  @Action
  public setWhiteLabelLoaded(loaded = true): void {
    this.context.commit(`updateState`, { whiteLabelLoaded: loaded });
  }

  @Action
  public setMfaSettings(mfaSettings: MfaSettings): void {
    const newMfaSettings = deepmerge(this.mfaSettings, mfaSettings);

    this.context.commit(`updateState`, { mfaSettings: newMfaSettings });
  }

  @Action
  public setLoginConfigHydrated(rehydrated: boolean) {
    this.context.commit(`updateState`, { rehydrated });
    getSentryInstance({ network: network.value, clientId: this.clientId });
  }

  @Action
  async setPreferencesAndRedirect(params: {
    redirectUrl: string;
    popupWindow: boolean;
    result: {
      tKey: string;
      oAuthPrivateKey: string;
      walletKey: string;
      // the below keys are only to optimize the apis.
      hasSkippedTkey?: boolean;
    };
    disableAlwaysSkip?: boolean;
    alwaysSkip?: boolean;
  }): Promise<void> {
    const operationName = PREFERENCES_OP;
    window.performance.mark(`${operationName}_start`);

    const { redirectUrl, result, popupWindow, alwaysSkip, disableAlwaysSkip } = params;

    const { userInfo, walletKeyInfo, keyInfo, keyMode, walletKey } = this.context.rootState[USER_MODULE_KEY] as IUserModuleState;
    const { settingsPageData } = (this.context.rootState[TKEY_MODULE_KEY] as ITkeyModuleState) || {};
    // get device share from here and return dapp share depending on login methods
    const {
      email,
      groupedAuthConnectionId,
      name,
      profileImage,
      authConnectionId,
      userId,
      idToken: oAuthIdToken,
      accessToken: oAuthAccessToken,
      authConnection,
    } = userInfo;
    let localDappShare = "";
    if (this.canSendDappShare) {
      // we can send dapp share in this case
      const exportedShare = await this.context.dispatch(`${TKEY_MODULE_KEY}/exportDeviceShare`, {}, { root: true });
      localDappShare = exportedShare;
    }
    const sessionStoreData = {
      appState: this.appState,
      email,
      groupedAuthConnectionId,
      name,
      profileImage,
      authConnection,
      authConnectionId,
      userId,
      dappShare: localDappShare || this.dappShare,
      oAuthIdToken: this.isCustomAuthConnectionId ? oAuthIdToken : "", // only send original id token for custom verifiers
      oAuthAccessToken: this.isCustomAuthConnectionId ? oAuthAccessToken : "",
      isMfaEnabled: keyMode === "v1" || keyMode === "2/n",
    };

    if (this.isAuthDashboard && !userModule.authToken) {
      await userModule.getAuthToken();
    }

    // if auth dashboard, generate a new session id as we dont want to modify the existing one used by dapp.
    const finalSessionId = this.isAuthDashboard ? SessionManager.generateRandomSessionKey() : userModule.sessionId;

    const metadataNonce = new BN(keyInfo.metadata.nonce);
    const finalResult: Required<AuthSession> = {
      privKey: "",
      tKey: "",
      coreKitEd25519PrivKey: "",
      coreKitKey: "",
      ed25519PrivKey: "",
      walletKey: "",
      sessionId: finalSessionId,
      oAuthPrivateKey: result.oAuthPrivateKey as string,
      userInfo: sessionStoreData,
      keyMode,
      metadataNonce: metadataNonce.eqn(0) ? "0" : metadataNonce.toString(16, 64),
      authToken: userModule.authToken,
      factorKey: "",
      signatures: userModule.sessionSignatures,
      tssNonce: 0,
      tssPubKey: "",
      tssShare: "",
      tssShareIndex: 0,
      nodeIndexes: [],
      shareDetails: parseShareDetails(settingsPageData),
      useCoreKitKey: this.useCoreKitKey,
      tssTag: "",
    };

    const loginMetadata: Partial<SaveLoginSessionMetadata> = {
      signatures: userModule.sessionSignatures,
      wallet_public_address: walletKeyInfo.keyData.walletAddress,
      app_scoped_public_address_ethereum: "",
      app_scoped_public_address_solana: "",
      dapp_public_key: "",
    };

    const keys: ExternalAuthTokenKey[] = [];

    const { getED25519Key, subkey } = await import("@web3auth/auth");

    const sessionNonce = getPublicCompressed(getBufferFromHexKey(userModule.sessionId)).toString("hex");

    if (result.tKey) {
      result.tKey = result.tKey.padStart(64, "0");
      const scopedKey = subkey(result.tKey, Buffer.from(this.clientId, "base64"));
      finalResult.privKey = scopedKey.padStart(64, "0");
      loginMetadata.app_scoped_public_address_ethereum = `0x${Buffer.from(
        pubToAddress(privateToPublic(getBufferFromHexKey(finalResult.privKey)))
      ).toString("hex")}`;
      finalResult.tKey = result.tKey;

      const ed25519Key = getED25519Key(finalResult.privKey as string);
      finalResult.ed25519PrivKey = ed25519Key.sk.toString("hex").padStart(128, "0");

      loginMetadata.app_scoped_public_address_solana = bs58.encode(ed25519Key.pk);

      if (this.isCustomAuthConnectionId && keyMode !== "v1") {
        finalResult.coreKitKey = result.tKey;
        finalResult.coreKitEd25519PrivKey = getED25519Key(result.tKey).sk.toString("hex").padStart(128, "0");
      }

      if (this.curve === SUPPORTED_KEY_CURVES.ED25519 || this.curve === SUPPORTED_KEY_CURVES.OTHER) {
        const app_pub_key = ed25519Key.pk.toString("hex");
        // This is subjected to replay attacks.
        const app_signature = await signMessage(ed25519Key.sk.toString("hex"), sessionNonce, SUPPORTED_KEY_CURVES.ED25519);
        const threshold_public_key = getED25519Key(result.tKey).pk.toString("hex");
        const threshold_signed_message = await signMessage(getED25519Key(result.tKey).sk.toString("hex"), sessionNonce, SUPPORTED_KEY_CURVES.ED25519);
        keys.push({ curve: SUPPORTED_KEY_CURVES.ED25519, pub_key: app_pub_key, signature: app_signature, type: EXTERNAL_AUTH_TOKEN.WEB3AUTH_APP });
        keys.push({
          curve: SUPPORTED_KEY_CURVES.ED25519,
          pub_key: threshold_public_key,
          signature: threshold_signed_message,
          type: EXTERNAL_AUTH_TOKEN.THRESHOLD,
        });
        loginMetadata.dapp_public_key = app_pub_key;
      }

      if (this.curve === SUPPORTED_KEY_CURVES.SECP256K1 || this.curve === SUPPORTED_KEY_CURVES.OTHER) {
        const app_pub_key = getPublicCompressed(getBufferFromHexKey(finalResult.privKey)).toString("hex");
        const app_signature = await signMessage(
          finalResult.privKey,
          hashMessage(sessionNonce, "hex").toString("hex"),
          SUPPORTED_KEY_CURVES.SECP256K1
        );
        const threshold_public_key = getPublicCompressed(getBufferFromHexKey(result.tKey)).toString("hex");
        const threshold_signed_message = await signMessage(
          result.tKey,
          hashMessage(sessionNonce, "hex").toString("hex"),
          SUPPORTED_KEY_CURVES.SECP256K1
        );
        keys.push({ curve: SUPPORTED_KEY_CURVES.SECP256K1, pub_key: app_pub_key, signature: app_signature, type: EXTERNAL_AUTH_TOKEN.WEB3AUTH_APP });
        keys.push({
          curve: SUPPORTED_KEY_CURVES.SECP256K1,
          pub_key: threshold_public_key,
          signature: threshold_signed_message,
          type: EXTERNAL_AUTH_TOKEN.THRESHOLD,
        });
        // incase of multi chain, dapp public key will be secp256k1 app key
        loginMetadata.dapp_public_key = app_pub_key;
      }
    }

    if (this.getWalletKey) {
      log.info("wallet key is being sent");
      const { dbUser } = userModule;
      // for v2 users who have enabled dual account mode via support settings
      if (dbUser?.v2_wallet_key_enabled && keyMode !== "v1") {
        finalResult.walletKey = walletKey?.padStart(64, "0") || "";
      } else {
        finalResult.walletKey = result.walletKey || "";
      }

      if (finalResult.walletKey) {
        const wallet_public_key = getPublicCompressed(getBufferFromHexKey(finalResult.walletKey)).toString("hex");
        const wallet_signed_message = await signMessage(
          finalResult.walletKey,
          hashMessage(sessionNonce, "hex").toString("hex"),
          SUPPORTED_KEY_CURVES.SECP256K1
        );
        keys.push({
          curve: SUPPORTED_KEY_CURVES.SECP256K1,
          pub_key: wallet_public_key,
          signature: wallet_signed_message,
          type: EXTERNAL_AUTH_TOKEN.WALLET,
        });
      }
    }

    // resetting it back to `dapp_public_key` false as tkey is generated and disableAlwaysSkip is sent as true
    if (disableAlwaysSkip && finalResult.tKey) {
      userModule.updateUserPersistedInfo({ payload: { always_skip_tkey: false } });
    } else if (alwaysSkip) {
      log.info("always skipping", alwaysSkip);
      userModule.updateUserPersistedInfo({ payload: { always_skip_tkey: true } });
    }

    const promises: Promise<string | void>[] = [];

    if (keys.length > 0) {
      try {
        if (!userModule.sessionId) {
          throw new Error("SessionId is missing while fetching external token");
        }
        log.debug("session nonce", sessionNonce);
        const oauth_pub_key = getPublicCompressed(getBufferFromHexKey(userModule.keyInfo.oAuthKeyData.privKey as string)).toString("hex");
        promises.push(
          getExternalAuthToken({
            client_id: this.clientId,
            timeout: this.sessionTime,
            curve: this.curve,
            email,
            name,
            verifier: authConnectionId,
            aggregate_verifier: groupedAuthConnectionId,
            verifier_id: userId,
            profile_image: profileImage,
            session_nonce: sessionNonce,
            oauth_public_key: oauth_pub_key,
            keys,
            network: network.value,
            public_address: keyInfo.keyData.walletAddress,
            signatures: userModule.sessionSignatures,
          })
        );
      } catch (error) {
        log.error("error while fetching external auth token", error);
      }
    }

    promises.push(this.saveLoginSessionMetadata({ loginMetadata }));

    try {
      const [token] = await Promise.all(promises);
      if (!token) {
        log.error("empty token found while fetching external auth token");
      } else {
        finalResult.userInfo.idToken = token;
      }
    } catch (error) {
      log.error("api error", error);
    }

    loginPerfModule.addLocalMetadataState({ loginRoute: "end" });

    const { loginId, sessionNamespace, storageServerUrl, customAuthInstanceId } = userModule;

    // resetting the session storage before successful redirect.
    // Here is a catch, if redirect to dapp fails then user would have to relogin again.
    // as the session data is cleared here.
    this.context.commit(`${USER_MODULE_KEY}/logout`, {}, { root: true });
    this.context.commit(`${TKEY_MODULE_KEY}/logout`, {}, { root: true });
    await redirectToDapp(
      {
        redirectUrl,
        popupWindow,
        sessionTime: this.sessionTime,
        sessionId: finalSessionId,
        loginId,
        sessionNamespace,
        appState: this.appState,
        storageServerUrl,
        instanceId: customAuthInstanceId,
        sdkMode: this.sdkMode,
      },
      { result: finalResult }
    );
  }

  @Action
  async redirect(): Promise<void> {
    const { sessionId, loginId, sessionNamespace } = userModule;
    await redirectToDapp(
      {
        redirectUrl: this.redirectUrl,
        popupWindow: this.uxMode === UX_MODE.POPUP,
        sessionTime: this.sessionTime,
        sessionId,
        loginId,
        sessionNamespace,
        appState: this.appState,
        syncSession: false,
        storageServerUrl: this.storageServerUrl,
        sdkMode: this.sdkMode,
      },
      { result: {} }
    );
  }

  @Action
  async logout(): Promise<void> {
    this.context.commit(`${USER_MODULE_KEY}/logout`, {}, { root: true });
    this.context.commit(`${TKEY_MODULE_KEY}/logout`, {}, { root: true });
    this.context.commit(
      "updateState",
      {
        customAuthConnectionConfig: [],
        redirectUrl: this.redirectUrl, // do not clear this, or logout redirects wont work
        whiteLabel: {},
        sessionId: "",
        sessionTime: 86400,
        dappShare: "",
        idToken: "",
        _sessionNamespace: "",
        mfaSettings: cloneDeep(config.value?.mfaSettings),
        whiteLabelLoaded: false,
      },
      { root: false }
    );
  }

  @Action
  async saveLoginSessionMetadata({ loginMetadata }: { loginMetadata: Partial<SaveLoginSessionMetadata> }): Promise<void> {
    const { localUserInfo } = userModule;
    const { localLoginMetadata, totalTimeTaken, authFactorsUsed, currentLoginPath } = loginPerfModule;
    const { userInfo, keyInfo } = userModule;
    const browser = bowser.getParser(window.navigator.userAgent);
    const specialBrowser = getCustomDeviceInfo();

    const payload: SaveLoginSessionMetadata = {
      ...loginMetadata,
      client_id: this.clientId,
      hostname: getHostNameForBackend(this.redirectUrl),
      network: network.value,
      public_address: keyInfo.keyData.walletAddress,
      user: {
        ...localUserInfo,
        verifier: userInfo.groupedAuthConnectionId || userInfo.authConnectionId,
        verifier_id: userInfo.userId,
      },
      login_record: {
        ...localLoginMetadata,
        login_type: userModule.keyMode as LoginType,
        login_route: localLoginMetadata.login_route || currentLoginPath,
        time_taken: totalTimeTaken,
        os: browser.getOSName(),
        os_version: browser.getOSVersion(),
        browser: specialBrowser?.browser || browser.getBrowserName(),
        browser_version: browser.getBrowserVersion(),
        platform: browser.getPlatform().type || "desktop",
        factors_used: authFactorsUsed.join("|"),
        webauthn_available: false,
        metadata: JSON.stringify({ pub_nonce: { x: keyInfo.metadata?.pubNonce?.X || "", y: keyInfo.metadata?.pubNonce?.Y || "" } }),
        app_version: config.value.appVersion,
      },
    };

    await saveLoginSessionMetadata(payload);
  }
}

export const dappModule = getModule(DappModule);
