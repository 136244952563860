import {
  AuthConnectionConfigItem,
  CustomAuth,
  CustomAuthLoginParams,
  handleRedirectParameters,
  RedirectResult,
  serializeError,
  storageAvailable,
  TorusGenericObject,
} from "@web3auth/auth";
import log from "loglevel";

import useConfig from "@/composables/useConfig";

import { CustomAuthStateParams, PasskeysCustomAuthStateParams } from "./interfaces";

const { config } = useConfig();

export function generateCustomAuthParams(
  localConfig: AuthConnectionConfigItem,
  customState: Partial<CustomAuthStateParams> = {}
): CustomAuthLoginParams {
  const state = {
    ...customState,
    version: config.value?.version as string,
  };
  if (localConfig.jwtParameters.isUserIdCaseSensitive === false && localConfig.jwtParameters.login_hint) {
    localConfig.jwtParameters.login_hint = localConfig.jwtParameters.login_hint.toLowerCase();
  }

  return {
    authConnection: localConfig.authConnection,
    authConnectionId: localConfig.authConnectionId,
    groupedAuthConnectionId: localConfig.groupedAuthConnectionId,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState: state,
  };
}

export function generatePasskeysCustomAuthParams(
  localConfig: AuthConnectionConfigItem,
  customState: Partial<PasskeysCustomAuthStateParams> = {}
): CustomAuthLoginParams {
  const state = {
    ...customState,
    version: config.value?.version as string,
  };

  return {
    authConnection: localConfig.authConnection,
    authConnectionId: localConfig.authConnectionId,
    groupedAuthConnectionId: localConfig.groupedAuthConnectionId,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState: state,
  };
}

export function clearLoginDetailsStorage(scope: string): void {
  if (storageAvailable("localStorage")) window.localStorage.removeItem(`torus_login_${scope}`);
  // No need to clear server details cause they auto expire and scope is never re-used for different login attempts
}

export async function getCustomAuthResult({
  customAuthInstance,
  customAuthArgs,
  hash,
  queryParams,
}: {
  customAuthInstance: CustomAuth;
  customAuthArgs: CustomAuthLoginParams;
  hash?: string;
  queryParams?: TorusGenericObject;
}): Promise<RedirectResult> {
  if (!customAuthArgs) {
    throw new Error("Custom auth args are required");
  }

  const { error, instanceParameters, hashParameters } = handleRedirectParameters(hash, queryParams);
  if (error) {
    return {
      error,
      state: instanceParameters || {},
      result: {},
      hashParameters,
      args: customAuthArgs,
    };
  }
  let result: unknown;

  try {
    const methodArgs = customAuthArgs;
    methodArgs.hash = hash;
    methodArgs.queryParameters = queryParams;
    result = await customAuthInstance.triggerLogin(customAuthArgs);
  } catch (err: unknown) {
    const serializedError = await serializeError(err);
    log.error(serializedError);
    return {
      error: `${serializedError.message || ""}`,
      state: instanceParameters || {},
      result: {},
      hashParameters,
      args: customAuthArgs,
    };
  }

  return { result, state: instanceParameters || {}, hashParameters, args: customAuthArgs };
}
