import { SESSION_SERVER_API_URL, TORUS_SAPPHIRE_NETWORK, TORUS_SAPPHIRE_NETWORK_TYPE } from "@toruslabs/constants";
import {
  AUTH_CONNECTION,
  AuthConnectionConfig,
  AuthConnectionConfigItem,
  type BUILD_ENV_TYPE,
  MFA_FACTOR,
  MfaSettings,
  storageAvailable,
  WEB3AUTH_NETWORK,
  type WEB3AUTH_NETWORK_TYPE,
} from "@web3auth/auth";
import bowser from "bowser";
import { computed, Ref, ref, watch } from "vue";

import browserInfo from "@/browserConfig";
import { EMAIL_FLOW, LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY } from "@/utils/enums";
import { AppConfig } from "@/utils/interfaces";

import buildEnv from "../config-build";
import configEnv from "../config-env";

const environment = import.meta.env.VITE_BUILD_ENV as BUILD_ENV_TYPE;
const network: Ref<WEB3AUTH_NETWORK_TYPE> = ref(WEB3AUTH_NETWORK.SAPPHIRE_MAINNET);
const metadataHost: Ref<string> = ref(configEnv[network.value].metadataHost);

const setNetwork = async (networkType: WEB3AUTH_NETWORK_TYPE) => {
  network.value = networkType;
};

const setMetadataHost = (metadataHostUrl: string) => {
  metadataHost.value = metadataHostUrl;
};

watch(network, (newNetwork: WEB3AUTH_NETWORK_TYPE, oldNetwork: WEB3AUTH_NETWORK_TYPE) => {
  // This is okay because setNetwork is only called once
  // we dont want to set it for sapphire networks, just a safe check here.
  if (newNetwork !== oldNetwork && !Object.values(TORUS_SAPPHIRE_NETWORK).includes(newNetwork as TORUS_SAPPHIRE_NETWORK_TYPE)) {
    setMetadataHost(configEnv[newNetwork].metadataHost);
  }
});

const useConfig = () => {
  const config = computed<AppConfig>(() => {
    const currentConfigEnv = configEnv[network.value];
    const currentBuildEnv = buildEnv[environment];
    if (!currentConfigEnv || !currentBuildEnv) {
      throw new Error("Invalid environment settings");
    }

    return {
      version: (import.meta.env.VITE_BUILD_VERSION ?? "").split(".")[0],
      appVersion: import.meta.env.VITE_APP_VERSION ? Number.parseInt(import.meta.env.VITE_APP_VERSION.split(".")[0], 10) : 0,
      signerHost: currentConfigEnv.signerHost,
      apiHost: currentBuildEnv.apiHost,
      passwordlessBackend: currentBuildEnv.passwordlessBackendHost,
      passwordlessHost: currentBuildEnv.passwordlessHost,
      storageServerUrl: SESSION_SERVER_API_URL,
      tkeyEmail: {
        host: "https://api.web3auth.io/tkey-email-service/send_mail",
        logo: "https://images.web3auth.io/web3auth-logo-passwordless-email.png",
        name: "Web3Auth",
      },
      alwaysAllowedHosts: ["localhost", "127.0.0.1", "sdk.openlogin.com", "demo-openlogin.web3auth.io", "web3auth.vercel.app"],
      allowedDashboardHosts: [
        "localhost",
        "web3auth.vercel.app",
        "develop-account.web3auth.io",
        "staging-account.web3auth.io",
        "account.web3auth.io",
      ],
      /* this is used to correctly check the origin for auth dashboard, else every localhost domain will be allowed. */
      allowedDashboardOrigins: [
        "localhost:5173",
        "web3auth.vercel.app",
        "develop-account.web3auth.io",
        "staging-account.web3auth.io",
        "account.web3auth.io",
      ],
      supportsVersioning: currentBuildEnv.supportsVersioning,
      redirectPath: `/auth`.slice(1),
      sentrySampleRate: currentBuildEnv.sentrySampleRate,
      sentryTransactionSampleRate: currentBuildEnv.sentryTransactionSampleRate,
      isStorageAvailable: {
        [LOCAL_STORAGE_KEY]: storageAvailable(LOCAL_STORAGE_KEY),
        [SESSION_STORAGE_KEY]: storageAvailable(SESSION_STORAGE_KEY),
      },
      prodNetworks: [
        WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
        WEB3AUTH_NETWORK.MAINNET,
        WEB3AUTH_NETWORK.CYAN,
        WEB3AUTH_NETWORK.AQUA,
        WEB3AUTH_NETWORK.CELESTE,
      ],
      mfaSettings: {
        [MFA_FACTOR.DEVICE]: {
          enable: true,
          priority: 1,
          mandatory: true,
        },
        [MFA_FACTOR.SOCIAL_BACKUP]: {
          enable: true,
          priority: 4,
          mandatory: false,
        },
        [MFA_FACTOR.BACKUP_SHARE]: {
          enable: true,
          priority: 5,
          mandatory: false,
        },
        [MFA_FACTOR.PASSWORD]: {
          enable: true,
          priority: 6,
          mandatory: false,
        },
        [MFA_FACTOR.PASSKEYS]: {
          enable: true,
          priority: 3,
          mandatory: false,
        },
        [MFA_FACTOR.AUTHENTICATOR]: {
          enable: true,
          priority: 2,
          mandatory: true,
        },
      } as MfaSettings,
      logLevel: currentBuildEnv.logLevel,
      // key is the login provider
      authConnectionConfig: [
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.googleVerifier,
          authConnection: AUTH_CONNECTION.GOOGLE,
          name: AUTH_CONNECTION.GOOGLE,
          description: "login.verifier-google-desc",
          clientId: currentConfigEnv.googleClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.googleVerifier : "",
          mainOption: true,
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletGoogleVerifier,
          jwtParameters: {
            prompt: browserInfo.platform === bowser.PLATFORMS_MAP.desktop ? "select_account" : "consent select_account",
          },
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.facebookVerifier,
          authConnection: AUTH_CONNECTION.FACEBOOK,
          name: AUTH_CONNECTION.FACEBOOK,
          description: "",
          clientId: currentConfigEnv.facebookClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.facebookVerifier : "",
          mainOption: true,
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletFacebookVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.twitterVerifier,
          authConnection: AUTH_CONNECTION.TWITTER,
          name: AUTH_CONNECTION.TWITTER,
          description: "",
          clientId: currentConfigEnv.twitterClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.twitterVerifier : "",
          mainOption: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "twitter",
            isUserIdCaseSensitive: false,
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletTwitterVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.discordVerifier,
          authConnection: AUTH_CONNECTION.DISCORD,
          name: AUTH_CONNECTION.DISCORD,
          description: "",
          clientId: currentConfigEnv.discordClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.discordVerifier : "",
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletDiscordVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.lineVerifier,
          authConnection: AUTH_CONNECTION.LINE,
          name: AUTH_CONNECTION.LINE,
          description: "",
          clientId: currentConfigEnv.lineClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.lineVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "line",
            prompt: "consent",
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletLineVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.redditVerifier,
          authConnection: AUTH_CONNECTION.REDDIT,
          name: AUTH_CONNECTION.REDDIT,
          description: "",
          clientId: currentConfigEnv.redditClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.redditVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            userIdField: "name",
            connection: "Reddit",
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletRedditVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.appleVerifier,
          authConnection: AUTH_CONNECTION.APPLE,
          name: AUTH_CONNECTION.APPLE,
          description: "",
          clientId: currentConfigEnv.appleClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.appleVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "apple",
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletAppleVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.githubVerifier,
          authConnection: AUTH_CONNECTION.GITHUB,
          description: "",
          name: AUTH_CONNECTION.GITHUB,
          clientId: currentConfigEnv.githubClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.githubVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "github",
            isUserIdCaseSensitive: false,
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletGithubVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.twitchVerifier,
          authConnection: AUTH_CONNECTION.TWITCH,
          description: "",
          name: AUTH_CONNECTION.TWITCH,
          clientId: currentConfigEnv.twitchClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.twitchVerifier : "",
          mainOption: false,
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletTwitchVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.linkedinVerifier,
          authConnection: AUTH_CONNECTION.LINKEDIN,
          description: "",
          name: AUTH_CONNECTION.LINKEDIN,
          clientId: currentConfigEnv.linkedinClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.linkedinVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "linkedin",
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletLinkedinVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.wechatVerifier,
          authConnection: AUTH_CONNECTION.WECHAT,
          description: "",
          name: "WeChat",
          clientId: currentConfigEnv.wechatClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.wechatVerifier : "",
          mainOption: false,
          showOnSocialBackupFactor: true,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "Wechat",
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletWechatVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.kakaoVerifier,
          authConnection: AUTH_CONNECTION.KAKAO,
          description: "",
          name: AUTH_CONNECTION.KAKAO,
          clientId: currentConfigEnv.kakaoClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.kakaoVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.loginDomain,
            connection: "Kakao",
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletKakaoVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.hostedEmailPasswordlessVerifier,
          description: "login.verifier-email-desc",
          authConnection: AUTH_CONNECTION.EMAIL_PASSWORDLESS,
          name: "email",
          clientId: currentConfigEnv.hostedEmailPasswordlessClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.hostedEmailPasswordlessVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentBuildEnv.passwordlessHost,
            userIdField: "name",
            isUserIdCaseSensitive: false,
            flow_type: EMAIL_FLOW.code,
          },
          // For torus only
          walletAuthConnectionId: currentConfigEnv.walletHostedEmailPasswordlessVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.hostedSmsPasswordlessVerifier,
          description: "login.verifier-sms-desc-2",
          authConnection: AUTH_CONNECTION.SMS_PASSWORDLESS,
          name: "mobile",
          clientId: currentConfigEnv.hostedSmsPasswordlessClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.hostedSmsPasswordlessVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentBuildEnv.passwordlessHost,
            userIdField: "name",
            isVerifierIdCaseSensitive: false,
          },

          // for torus only.
          walletAuthConnectionId: currentConfigEnv.walletHostedSmsPasswordlessVerifier,
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.passkeysVerifier,
          description: "login.verifier-webauth-desc",
          authConnection: AUTH_CONNECTION.PASSKEYS,
          name: AUTH_CONNECTION.PASSKEYS,
          clientId: currentConfigEnv.passkeysClientId,
          mainOption: false,
          // For torus only
          walletAuthConnectionId: "",
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.hostedFarcasterVerifier,
          description: "",
          authConnection: AUTH_CONNECTION.FARCASTER,
          name: "Farcaster",
          clientId: currentConfigEnv.hostedFarcasterClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.hostedFarcasterVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentConfigEnv.farcasterLoginDomain,
          },
          // For torus only
          walletAuthConnectionId: "",
        } as AuthConnectionConfigItem,
        {
          authConnectionId: currentConfigEnv.verifierSubIdentifier || currentConfigEnv.authenticatorVerifier,
          description: "",
          authConnection: AUTH_CONNECTION.AUTHENTICATOR,
          name: AUTH_CONNECTION.AUTHENTICATOR,
          clientId: currentConfigEnv.authenticatorClientId,
          groupedAuthConnectionId: currentConfigEnv.verifierSubIdentifier ? currentConfigEnv.authenticatorVerifier : "",
          mainOption: false,
          jwtParameters: {
            domain: currentBuildEnv.passwordlessHost,
            userIdField: "name",
            connection: "authenticator",
            isUserIdCaseSensitive: false,
            network: network.value,
          },
          // For torus only
          walletAuthConnectionId: "",
        } as AuthConnectionConfigItem,
      ] as AuthConnectionConfig,
    } as AppConfig;
  });

  const authVerifiers = computed(() => {
    if (config.value === null) return [];
    return config.value.authConnectionConfig.reduce((acc: string[], x: AuthConnectionConfigItem) => {
      acc.push(x.authConnectionId);
      if (x.walletAuthConnectionId) acc.push(x.walletAuthConnectionId);
      if (x.groupedAuthConnectionId) acc.push(x.groupedAuthConnectionId);
      return acc;
    }, []);
  });

  const isProdNetwork = computed(() => config.value.prodNetworks.includes(network.value));

  const isSapphireNetwork = computed(() => {
    return [TORUS_SAPPHIRE_NETWORK.SAPPHIRE_MAINNET, TORUS_SAPPHIRE_NETWORK.SAPPHIRE_DEVNET].includes(network.value as TORUS_SAPPHIRE_NETWORK_TYPE);
  });

  return { config, authVerifiers, environment, network, setNetwork, metadataHost, setMetadataHost, isProdNetwork, isSapphireNetwork };
};

export default useConfig;
